<template>
  <div class="content">
    <div class="holder holder--between holder--indent">
      <div class="content__party content__party--start content__party--no-indent">
        <button
          @click="onMoveList"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                  fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                  fill="#2B93E7"/>
          </svg>
        </button>
        <h1 class="title title--big title--color">Заявка на получение карты (ID: {{ requestId }})</h1>
        <div class="status" :class="'status--'+statusEn">
          <span>{{ statusName }}</span>
        </div>
      </div>
      <button @click="openSidebar" class="button button--light button--no-indent">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 17.0134L11.413 16.9984L21.045 7.45839C21.423 7.08039 21.631 6.57839 21.631 6.04439C21.631 5.51039 21.423 5.00839 21.045 4.63039L19.459 3.04439C18.703 2.28839 17.384 2.29239 16.634 3.04139L7 12.5834V17.0134ZM18.045 4.45839L19.634 6.04139L18.037 7.62339L16.451 6.03839L18.045 4.45839ZM9 13.4174L15.03 7.44439L16.616 9.03039L10.587 15.0014L9 15.0064V13.4174Z"
            fill="#2B93E7"/>
          <path
            d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
            fill="#2B93E7"/>
        </svg>
        <span>Управление заявкой</span>
      </button>
    </div>
    <div v-if="comment" class="comment">
      <div class="comment__top">
        <img src="@/assets/img/alert.svg" alt="Alert">
        <span>Комментарий:</span>
      </div>
      <p>{{ comment }}</p>
    </div>
    <h2 class="title title--indent">Основные сведения</h2>
    <form class="form" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group" :class="{ 'form-group--error': $v.photoUrl.$error }">
            <label>Фотография<sup>*</sup></label>
            <cropper-modal
              @clearPhoto="clearPhoto"
              :getCropCoords="getCropCoords"
              :getCropHash="getCropHash"
              :originalImgUrlProp="photoUrl"
              :croppedImgUrlProp="photoCropUrl"
              :stencilProps="{ aspectRatio: 3 / 4 }"
              ref="cropperModal"
              :class="{'cropper--readonly' : status === 3 || status === 4}"
            />
            <div class="form-group__error" v-if="$v.photoUrl.$dirty && !photoUrl">Обязательное поле</div>
          </div>
          <div v-if="form.type_of" class="form-group">
            <label>Тип</label>
            <input
              id="type_of"
              type="text"
              class="form-control__input"
              name="lastname"
              placeholder="Иванов"
              readonly
              v-model.trim="form.type_of.name"
            />
          </div>
          <div class="form-group">
            <label>Тариф<sup>*</sup></label>
            <v-select
              label="name"
              :options="tariffOptions"
              :reduce="tariff => tariff.id"
              v-model="form.tariff"
              @change="$v.form.tariff.$touch()"
              :searchable="false"
              class="select"
              :class="{ 'select--error': $v.form.tariff.$error }"
              :get-option-label="getLabel"
              placeholder="Выберите значение"
              readonly
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template slot="clear-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }} ({{ option.price }} &#8381;)</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }} ({{ option.price }} &#8381;)</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.tariff.$dirty && !$v.form.tariff.required">Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="lastname">Фамилия<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.lastname.$error }">
              <input
                id="lastname"
                type="text"
                class="form-control__input"
                name="lastname"
                placeholder="Иванов"
                :readonly="status === 3 || status === 4"
                v-model.trim="form.lastname"
                @change="$v.form.lastname.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.required">Обязательное
              поле
            </div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.ruAlpha">Принимаются
              только русские буквы алфавита
            </div>
          </div>
          <div class="form-group">
            <label for="firstname">Имя<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.firstname.$error }">
              <input
                id="firstname"
                type="text"
                class="form-control__input"
                name="firstname"
                placeholder="Иван"
                :readonly="status === 3 || status === 4"
                v-model.trim="form.firstname"
                @change="$v.form.firstname.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.required">Обязательное
              поле
            </div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group">
            <label for="middlename">Отчество</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.middlename.$error }">
              <input
                id="middlename"
                type="text"
                class="form-control__input"
                name="middlename"
                placeholder="Иванович"
                :readonly="status === 3 || status === 4"
                v-model.trim="form.middlename"
                @change="$v.form.middlename.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.middlename.$dirty && !$v.form.middlename.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.dob.$error }">
            <label>Дата рождения<sup>*</sup></label>
            <date-picker
              v-model="form.dob"
              name="dob"
              format="DD.MM.YYYY"
              value-type="DD.MM.YYYY"
              placeholder="дд.мм.гггг"
              :editable="true"
              ref="datepicker"
              :class="{ 'mx-datepicker--error': $v.form.dob.$error, 'mx-datepicker--readonly': status === 3 || status === 4 }"
            >
              <template #input="{ props, event }">
                <masked-input mask="11.11.1111" :value="props.value" v-bind="props" v-on="event" v-model="form.dob"/>
              </template>
            </date-picker>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.form.dob.required">Обязательное поле</div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="email">Электронная почта<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="mail@example.com"
                :readonly="status === 3 || status === 4"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div class="form-group">
            <label>Телефон<sup>*</sup></label>
            <vue-tel-input
              :value="phoneValue"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': $v.form.phone.$dirty && !isPhoneValid, 'phonepicker--readonly': status === 3 || status === 4 }"
              @validate="validedPhone"
              @blur="phoneBlur"
              disabledFetchingCountry
              validCharactersOnly
              dynamicPlaceholder
              autocomplete="phone_autocomplete_off"
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле
            </div>
            <div class="form-group__error" v-if="$v.form.phone.$dirty && !isPhoneValid">Недопустимое значение для номера
              телефона
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.passportUrl.$error }">
            <label>Скан-копия паспорта (основной разворот) <sup>*</sup></label>
            <FakeUploader v-show="passportUrl" @deleteFakePassport="clearPassport" :photoUrl="passportUrl"
                          :class="{'fake-uploader--readonly': status === 3 || status === 4}"/>
            <uploader v-show="!passportUrl" :getFileHash="getFileHash" limit-files="1" :fileUrl="fileUrl"/>
            <div class="form-group__error" v-if="$v.passportUrl.$dirty && !$v.passportUrl.required">Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Регион<sup>*</sup></label>
            <v-select
              :options="regionOptions"
              label="name"
              :reduce="region => region.id"
              v-model="form.region"
              :filterable="false"
              :clearable="false"
              @search="onRegionSearch"
              class="select"
              :get-option-label="getLabel"
              :class="{ 'select--error': $v.form.region.$error }"
              placeholder="Выберите регион"
              readonly
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой регион</template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.region.$dirty && !$v.form.region.required">Обязательное поле
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.type_delivery.$error }">
            <label>Доставка карты<sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio radio--readonly">
                <div class="radio__text">Самовывоз</div>
                <input type="radio" name="delivery" v-model.number="form.type_delivery" value="1"/>
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio radio--readonly">
                <div class="radio__text">Почта России</div>
                <input type="radio" name="delivery" v-model.number="form.type_delivery" value="2"/>
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.type_delivery.$dirty && !$v.form.type_delivery.required">
              Обязательное поле
            </div>
          </div>
          <template v-if="form.type_delivery === 1">
            <div class="form-group  form-group--readonly">
              <label>Город самовывоза:</label>
              <v-select
                label="name"
                :options="pickupOptions"
                :reduce="pickup_city => pickup_city.id"
                v-model="form.pickup_city"
                :searchable="false"
                class="select"
                :get-option-label="getLabel"
                placeholder="Выберите регион для самовывоза"
                readonly
              >
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
            </div>
          </template>
          <template v-if="form.type_delivery === 2">
            <div class="form-group">
              <label>Адрес доставки</label>
              <v-select
                label="address"
                v-model="form.delivery"
                :filterable="false"
                :options="deliveryRegionOptions"
                @search="onDeliveryRegionSearch"
                :get-option-label="getLabel"
                placeholder="Введите свой адрес"
                class="select"
                readonly
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                       fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                          stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template slot="no-options">введите для поиска свой адрес..</template>
                <template slot="option" slot-scope="option">
                  <div class="select__item d-center">{{ option.post_index }}, {{ option.address }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">{{ option.post_index }}, {{ option.address }}</div>
                </template>
              </v-select>
              <div class="form-group__info" v-if="form.delivery">
                {{ form.delivery.address.length > 64 ? `${form.delivery.post_index}, ${form.delivery.address}` : "" }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit" :disabled="status === 3 || status === 4 || $v.form.$error">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
    <SidebarRight
      title="Управление"
      :class="{'sidebar-manage--full': showSidebar}"
      @close-sidebar="showSidebar = !showSidebar"
    >
      <form class="form" @submit.prevent="sendSubForm">
        <div class="form-group">
          <label for="comment">Комментарий</label>
          <div class="form-control">
            <textarea
              id="comment"
              name="comment"
              class="form-control__textarea"
              v-model.trim="comment"
              placeholder="Ввести комментарий"
              @blur.stop="sendComment"
            >
            </textarea>
          </div>
        </div>
        <div v-if="status === 2" class="form-group">
          <label>Дата начала</label>
          <date-picker
            v-model="date_start"
            name="date_start"
            format="DD.MM.YYYY"
            value-type="DD.MM.YYYY"
            placeholder="дд.мм.гггг"
            ref="datepicker"
            class="mx-datepicker--readonly"
          >
          </date-picker>
        </div>
        <div v-if="status === 2" class="form-group">
          <label>Дата окончания</label>
          <date-picker
            v-model="date_end"
            name="date_end"
            format="DD.MM.YYYY"
            value-type="DD.MM.YYYY"
            placeholder="дд.мм.гггг"
            :editable="true"
            ref="datepicker"
          >
            <template #input="{ props, event }">
              <masked-input mask="11.11.1111" :value="props.value" v-bind="props" v-on="event" v-model="date_end"/>
            </template>
          </date-picker>
        </div>
        <button
          v-if="showDownloadBtns"
          @click="downloadCard(card_print_url, `${form.firstname} ${form.lastname} - карта для печати`, $event)"
          type="button"
          class="button button--transparent button--stretched button--indent-bottom"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 17.25H19.125C19.6218 17.2485 20.0979 17.0505 20.4492 16.6992C20.8005 16.3479 20.9985 15.8718 21 15.375V7.875C20.9985 7.37818 20.8005 6.90212 20.4492 6.55081C20.0979 6.1995 19.6218 6.00148 19.125 6H4.875C4.37818 6.00148 3.90212 6.1995 3.55081 6.55081C3.1995 6.90212 3.00148 7.37818 3 7.875V15.375C3.00148 15.8718 3.1995 16.3479 3.55081 16.6992C3.90212 17.0505 4.37818 17.2485 4.875 17.25H6"
              stroke="#2B93E7" stroke-width="2" stroke-linejoin="round"/>
            <path
              d="M16.86 13H7.14C6.5104 13 6 13.4188 6 13.9354V20.0646C6 20.5812 6.5104 21 7.14 21H16.86C17.4896 21 18 20.5812 18 20.0646V13.9354C18 13.4188 17.4896 13 16.86 13Z"
              stroke="#2B93E7" stroke-width="2" stroke-linejoin="round"/>
            <path
              d="M18 6V4.5C17.9985 3.83757 17.8005 3.20283 17.4492 2.73442C17.0979 2.26601 16.6218 2.00198 16.125 2H7.875C7.37818 2.00198 6.90212 2.26601 6.55081 2.73442C6.1995 3.20283 6.00148 3.83757 6 4.5V6"
              stroke="#2B93E7" stroke-width="2" stroke-linejoin="round"/>
            <path
              d="M17.125 10.25C17.7463 10.25 18.25 9.74632 18.25 9.125C18.25 8.50368 17.7463 8 17.125 8C16.5037 8 16 8.50368 16 9.125C16 9.74632 16.5037 10.25 17.125 10.25Z"
              fill="#2B93E7"/>
          </svg>
          <span>Скачать карту для печати</span>
        </button>
        <button
          v-if="showDownloadBtns"
          @click="downloadCard(card_url, `${form.firstname} ${form.lastname} - полная карта`, $event)"
          type="button"
          class="button button--transparent button--stretched button--indent-bottom"
        >
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" d="M1 6c0-1.1.9-2 2-2h18a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2V6z"
                  stroke="#2B93E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 10h22" stroke="#2B93E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Скачать карту</span>
        </button>
        <button v-if="status === 2 || status === 3" class="button button--stretched" type="submit"
                :class="{preload: showPreload}">
          <img src="@/assets/img/exchange-icon.svg" alt="">
          <span v-show="status === 2">Подтвердить</span>
          <span v-show="status === 3">Завершить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
        <button v-if="status === 1 || status === 5" class="button button--stretched" type="submit"
                :class="{preload: showPreload}">
          <img src="@/assets/img/archive.svg" alt="">
          <span v-show="status === 1">В архив</span>
          <span v-show="status === 5">Вернуть</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </form>
    </SidebarRight>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, email, helpers, requiredIf} from 'vuelidate/lib/validators';
import moment from 'moment';
import {debounce, omit} from 'lodash';

import CropperModal from '@/components/Cropper';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import MaskedInput from 'vue-masked-input';
import {VueTelInput} from 'vue-tel-input';
import Uploader from '@/components/Uploader';
import FakeUploader from '@/components/FakeUploader';
import SidebarRight from '@/components/SidebarRight';
import axiosAdmin from '@/http/axios/axiosAdmin';

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i);

export default {
  mixins: [validationMixin],
  components: {
    CropperModal,
    DatePicker,
    MaskedInput,
    VueTelInput,
    Uploader,
    FakeUploader,
    SidebarRight
  },
  data() {
    return {
      form: {
        status: null,
        tariff: null,
        lastname: '',
        firstname: '',
        middlename: null,
        dob: '',
        phone: null,
        email: '',
        region: null,
        type_delivery: null,
        pickup_city: null,
        delivery: null,
        photo: '',
        passport: '',
        coords: {},
        hash: ''
      },
      tariffOptions: [],
      regionOptions: [],
      deliveryRegionOptions: [],
      pickupOptions: [],
      isPhoneValid: null,
      phoneValue: '',
      openDatepicker: false,
      passportUrl: '',
      photoUrl: '',
      photoCropUrl: null,
      comment: '',
      oldComment: '',
      date_start: moment(new Date()).format('DD.MM.YYYY'),
      date_end: '',
      status: null,
      count_year: null,
      card_print_url: '',
      card_url: '',
      showSidebar: false,
      showPreload: false,
      showDownloadBtns: false,
      requestId: null,
      fileUrl: `${process.env.VUE_APP_API_URL}files`,
      cropOpt: {
        size: 1,
        outputType: 'png',
      },
      statusName: '',
      statusPlural: '',
      statusEn: '',
    };
  },
  watch: {
    count_year() {
      this.date_end = moment(new Date).add(this.count_year, 'years').format('DD.MM.YYYY');
    }
  },
  validations: {
    form: {
      tariff: {required},
      lastname: {required, ruAlpha},
      firstname: {required, ruAlpha},
      middlename: {ruAlpha},
      dob: {required},
      phone: {required},
      email: {required, email},
      region: {required},
      type_delivery: {required},
    },
    passportUrl: {required},
    photoUrl: {required}
  },
  async created() {
    await this.fetchCard();
    if (this.status === 3 || this.status === 4) {
      this.showDownloadBtns = true;
    }
    this.$store.dispatch('admin/request/GET_TARIFFS')
      .then(response => (this.tariffOptions = response.data.results));
  },
  methods: {
    clearPhoto() {
      this.form.photo = '';
    },
    checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (this.form.coords.hasOwnProperty('width') && !this.form.photo) {
          this.$refs.cropperModal.sendAdminCrop()
            .then(() => {
              this.sendForm();
            });
        } else {
          this.sendForm();
        }
      }
    },
    sendForm() {
      if (this.form.passport === '') {
        this.form = omit(this.form, ['passport']);
      }
      if (this.form.delivery === null) {
        this.form = omit(this.form, ['delivery']);
      }
      const data = {
        status: this.form.status.id,
        tariff: this.form.tariff.id,
        region: this.form.region.id,
        delivery: this.form.delivery,
        coords: this.form.coords,
        photo: this.form.photo,
        passport: this.form.passport,
        lastname: this.form.lastname,
        firstname: this.form.firstname,
        middlename: this.form.middlename,
        dob: this.form.dob,
        phone: this.form.phone,
        email: this.form.email,
        type_delivery: this.form.type_delivery,
        hash: this.form.hash,
      };
      if (this.form.pickup_city) {
        data.pickup_city = this.form.pickup_city.id;
      }
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('admin/request/PATCH_DATA', {hash: this.$route.params.hash, data})
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Данные успешно сохранены'
          });
          this.$store.commit('admin/request/changeStatus', this.status);
          this.$store.commit('admin/request/changeStatusName', this.statusName);
          this.$router.push({name: 'request-list'});
        })
        .catch(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'Ошибка сохранения данных'
          });
        });
    },
    async fetchCard() {
      try {
        const hash = this.$route.params.hash;
        const response = await this.$store.dispatch('admin/request/GET_DATA', hash);
        if (response.status >= 400) {
          throw Error('Ошибка получения данных');
        }
        this.form = response.data;
        this.form.passport = '';
        this.phoneValue = response.data.phone;
        this.photoUrl = response.data.photo_url;
        this.photoCropUrl = response.data.crop_url;
        this.passportUrl = response.data.passport_url;
        this.count_year = response.data.tariff.count_year;
        this.comment = response.data.comment;
        this.oldComment = response.data.comment;
        this.status = response.data.status.id;
        this.statusName = response.data.status.name;
        this.statusPlural = response.data.status.name_plural;
        this.statusEn = response.data.status.name_eng;
        this.card_print_url = response.data.card_print_url;
        this.card_url = response.data.card_url;
        this.requestId = response.data.id;
        this.pickupOptions.push(response.data.pickup_city);
        this.form.coords = {};
        //this.form.delivery = `${response.data.delivery.post_index}, ${response.data.delivery.address}`
      } catch (e) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Ошибка получения данных'
        });
      }
    },
    clearPassport() {
      this.passportUrl = null;
      this.form.passport = '';
    },
    getCropCoords(value) {
      this.form.coords = value;
    },
    getCropHash(value) {
      this.form.photo = value;
    },
    getFileHash(value) {
      this.form.passport = value;
    },
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onRegionSearch(search, loading) {
      loading(true);
      this.searchRegion(loading, search, this);
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/request/GET_REGIONS', {search}).then(response => {
        vm.regionOptions = response.data;
        loading(false);
      });
    }, 350),
    onDeliveryRegionSearch(search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/request/GET_ADDRESS', {search}).then(response => {
        vm.deliveryRegionOptions = response.data;
        loading(false);
      });
    }, 350),
    validedPhone(number) {
      this.isPhoneValid = number.valid;
      if (number.valid) {
        this.form.phone = number.number.e164;
      } else {
        this.form.phone = null;
      }
    },
    phoneBlur() {
      this.$v.form.phone.$touch();
    },
    sendSubForm() {
      this.showPreload = true;
      if (this.status === 2) this.status = 3;
      else if (this.status === 3) this.status = 4;
      else if (this.status === 1) this.status = 5;
      else if (this.status === 5) this.status = 1;
      const data = {
        date_start: this.date_start,
        date_end: this.date_end,
        status: this.status,
        dob: this.form.dob,
      };
      fetch(`${process.env.VUE_APP_API_URL}card-request/update/${this.$route.params.hash}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        method: 'PATCH',
        body: JSON.stringify(data),
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          if (json.date_end) {
            if (this.status === 3) this.status = 2;
            this.showPreload = false;
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: json.date_end[0],
            });
          } else {
            this.fetchCard();
            this.showDownloadBtns = true;
            this.showPreload = false;
            this.showSidebar = false;
            this.$notify({
              type: 'success',
              title: 'Уведомление',
              text: 'Статус заявки успешно изменен',
            });
          }
        })
        .catch(response => {
          this.showPreload = false;
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });

      // this.$store.dispatch('admin/request/PATCH_DATA', {hash: this.$route.params.hash, data})
      //   .then(() => {
      //     this.fetchCard();
      //     this.showDownloadBtns = true;
      //     this.showPreload = false;
      //     this.showSidebar = false;
      //     this.$notify({
      //       type: 'success',
      //       title: 'Уведомление',
      //       text: 'Статус заявки успешно изменен',
      //     });
      //   })
      //   .catch(error => {
      //     this.showPreload = false;
      //     console.log(333);
      //     this.$notify({
      //       type: 'error',
      //       title: 'Ошибка',
      //       text: error.message
      //     });
      //   });
    },
    async sendComment() {
      const data = {
        comment: this.comment
      };
      try {
        if (this.comment !== this.oldComment) {
          await this.$store.dispatch('admin/request/PATCH_DATA', {hash: this.$route.params.hash, data});
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Комментарий сохранен'
          });
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: error.message
        });
      }
    },
    downloadCard(url, fileName) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          if (fileName.length && url.length) {
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = fileName;
            a.style = 'display: none';
            a.click();
          }
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    openSidebar() {
      this.showSidebar = true;
    },
    onMoveList() {
      this.$store.commit('admin/request/changeStatus', this.status);
      this.$store.commit('admin/request/changeStatusName', this.statusPlural);
      this.$router.push({name: 'request-list'});
    },
  }
};
</script>